export default [
  {
    title: 'Dashboard',
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'Membership',
        route: 'membership-db',
        icon: 'CircleIcon',
      },
      {
        title: 'Contribution',
        route: 'contribution-db',
        icon: 'CircleIcon',
      },
      {
        title: 'Documents',
        route: 'documents-db',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Administration',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Company',
        route: 'company',
        icon: 'CircleIcon',
      },
      {
        title: 'Farmer Groups',
        route: 'organizations',
        icon: 'CircleIcon',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'CircleIcon',
      },
      {
        title: 'Numberings',
        route: 'numberings',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Membership',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Members',
        route: 'members',
        icon: 'CircleIcon',
      },
      {
        title: 'ID Customization',
        route: 'id-customization',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Contribution',
    icon: 'GiftIcon',
    children: [
      {
        title: 'Settings',
        route: 'settings',
        icon: 'CircleIcon',
      },
      {
        title: 'Payments',
        route: 'payments',
        icon: 'CircleIcon',
      },
      {
        title: 'Outstanding',
        route: 'outstanding',
        icon: 'CircleIcon',
      },
      {
        title: 'Grants',
        route: 'grants',
        icon: 'CircleIcon',
      },
    ],
  },
  {
    title: 'Documents',
    icon: 'BookIcon',
    children: [
      {
        title: 'Folders',
        route: 'folders',
        icon: 'CircleIcon',
      },
      {
        title: 'Trash Store',
        route: 'trash',
        icon: 'CircleIcon',
      },
    ],
  },
]
