<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <am-avatar
        size="40"
        :src="companyLogo"
        class="mr-1"
      />
      <span>{{ companyName }}</span>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ authUser.first_name }} {{ authUser.last_name }}
            </p>
            <span class="user-status">{{ authUser.role }}</span>
          </div>
          <am-avatar
            size="40"
            variant="light-primary"
            :src="authUser.photo"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="gotoProfile()"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="MailIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Inbox</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="CheckSquareIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Task</span>-->
        <!--        </b-dropdown-item>-->

        <dark-Toggler class="d-none d-lg-block" />

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import showToast from '@/farmbusiness/mixins/showToast'
import AmAvatar from '@/farmbusiness/components/AmAvatar.vue'

export default {
  components: {
    AmAvatar,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  mixins: [showToast],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    companyName() {
      return this.$store.getters['company/company']?.name
    },
    companyLogo() {
      return this.$store.getters['company/companyLogo']
    },
    authUser() {
      return this.$store.getters['auth/user']
    },
  },
  methods: {
    gotoProfile() {
      this.$router.push({ name: 'profile' })
    },
    logout() {
      this.$store.dispatch('auth/logout').finally(res => {
        this.showSuccess('Log out successful')
        this.$router.push({ name: 'login' })
      })
    },
  },
}
</script>
