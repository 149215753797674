<template>
  <b-avatar
    :size="size"
    :src="photo"
    variant="light-primary"
    style="background-color: transparent !important;"
  />

</template>

<script>
import {
  BAvatar,
} from 'bootstrap-vue'

export default {
  name: 'AmAvatar',
  components: {
    BAvatar,
  },
  props: {
    src: { type: String, default: '' },
    check: { type: String, default: 'farmbusiness' },
    size: { type: Number | String, default: 60 },
  },
  computed: {
    photo() {
      return `${process.env.VUE_APP_STORAGE_PATH}/${this.src}`
    },
  },
}
</script>

<style scoped>

</style>
